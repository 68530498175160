import React from 'react';
import { useState } from 'react';
import EyeOpenUrl from './eye.svg'
import EyeClosedUrl from './eye_closed.svg'

const InputField = ({
    label,
    onChange,
    placeholder,
    name,
    error,
    value,
    readOnly,
    type,
    action,
}) => {

    let [visible, setVisible] = useState(false)

    return (
        <div
            style={{
                padding: '12px 0px',
            }}
        >
            <div
                style={{
                    fontWeight: '500',
                    fontSize: '12px',
                    lineHeight: '24px',
                    letterSpacing: '0.23px',
                    fontFamily: 'RaleWay-Medium',
                    display: 'flex',
                    justifyContent: 'space-between',
                }}
            >
                <div>
                    {label}
                </div>
                <div style={{
                    color: 'rgb(255, 94, 94)',
                }}
                >
                    {error}
                </div>
            </div>
            <div style={{
                position: 'relative',
            }}>
                <input type={
                    visible ? 'text' :
                        type ?? 'text'
                }
                    disabled={readOnly}
                    placeholder={placeholder ?? label}
                    value={value}
                    name={name ?? label.toLowerCase()}
                    style={{
                        backgroundColor: 'transparent',
                        fontFamily: 'RaleWay-Medium',
                        fontWeight: '500',
                        outline: 'none',
                        fontSize: '16px',
                        lineHeight: '24px',
                        letterSpacing: 0,
                        color: 'rgb(30,30,30)',
                        height: '40px',
                        width: '100%',
                        border: 'none',
                        borderBottom: `1px solid ${error ? 'rgb(255, 94, 94)' : 'rgba(48, 48, 48, 0.2)'}`,
                    }}

                    onChange={onChange}
                />
                {
                    type === 'password' &&

                    <img alt='' src={`${visible ? EyeOpenUrl : EyeClosedUrl}`}
                        style={{
                            position: 'absolute',
                            right: 8,
                            top: visible ? 8 : 16,
                            width: 20,
                            height: 20,
                            cursor: 'pointer',
                        }}
                        onClick={() => setVisible(!visible)}
                    />
                }

                {action}
            </div>
        </div>
    )

}

export default InputField;
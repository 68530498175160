import React from 'react';
import './form_button.css';

const FormButton = ({
    text,
    loading,
    disabled = false,
    onClick,
    width,
}) => {
    return (

        <button
            className="button"
            type={'submit'}
            disabled={disabled}

            style={{
                textTransform: 'none',
                // width: width ?? '100%',
                minWidth: 200,
                borderRadius: '6pt',
                height: '50px',
                backgroundColor: `rgba( 0, 0, 0, ${loading ? 0.4 : 0.92})`,
                cursor: disabled ? 'default' : 'pointer',
                opacity: disabled ? 0.5 : 1.0,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                color: 'white',
                fontSize: '16px',
                lineHeight: '24px',
                fontWeight: 'bold',
                border: 'none',
                outline: 'none',
                margin: '10px 0px',
                padding: '0px 50px'
            }}

            onClick={!loading ? onClick : undefined}
        >
            {
                loading
                    ? <div className={'fa fa-spinner fa-spin'} />
                    : text
            }

        </button>
    )
}

export default FormButton;
import { Chip, createMuiTheme, Snackbar, Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import { makeStyles, ThemeProvider } from '@material-ui/core/styles';
import Alert from '@material-ui/lab/Alert';
import React, { useEffect, useState } from 'react';
import { EditUserDialog } from './edit_dialog';
import { InvitationDialog } from './invitation_dialog';
import { db } from '../firebase/index';

const OutlinedButton = ({ onClick, children }) =>
    <div
        style={{
            display: 'flex',
            alignItems: 'center',
            padding: '0px 20px',
            height: '40px',
            borderRadius: 4,
            border: '2px solid black',
            fontFamily: 'Raleway-Regular',
            fontWeight: '700',
            fontSize: '14px',
            lineHeight: '22px',
            cursor: 'pointer'
        }}
        onClick={onClick}
    >
        {children}
    </div>

const useStyles = makeStyles(() => ({
    header: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    table: {
        width: '100%',
    }
}));

export function TeamManagementPage({ teamName, role, token }) {
    const classes = useStyles();

    var headers = ['name', 'email', 'role', 'status'];
    const [tableData, setTableData] = useState([]);
    const [dataChanged, setDataChanged] = useState(false);

    // load member list
    useEffect(() => {
        var tableRows = [];

        db.collection('teams').doc(teamName).collection('members').get({})
            .then(response => {
                response.forEach(doc => {
                    let data = doc.data();
                    tableRows.push({
                        uid: doc.id,
                        status: 'accepted',
                        name: data.name ?? doc.id.substr(0, 5),
                        role: data.role ?? '',
                        email: data.email ?? '',
                    });
                })
            })
            .catch((err) => {
                console.error(err);
            })

        db.collection('teams').doc(teamName).collection('invites').get({})
            .then(response => {
                response.forEach(doc => {
                    let data = doc.data();
                    tableRows.push({
                        inviteId: doc.id,
                        status: data.status ?? 'invitation sent',
                        name: data.name ?? '',
                        role: data.role ?? '',
                        email: data.email ?? '',
                    });
                })
            })
            .catch((err) => {
                console.error(err);
            })
            .finally(() => {
                setTableData(tableRows);
            })

        setDataChanged(false);
    }, [teamName, dataChanged])


    const theme = createMuiTheme({
        typography: {
            fontFamily: 'Raleway-Regular',
            fontSize: 15,
        },
    });


    const statusAcceptedStyle = {
        backgroundColor: 'rgb(237, 255, 241)', color: 'rgb(105, 213, 148)',
    }

    const statusDefaultStyle = {
        backgroundColor: 'rgb(247, 247, 247)', color: 'rgb(140, 140, 140)'
    }

    const NameComponent = ({ image, text }) => {
        return (
            <div>
                <img alt='' src={image} width='24' height='24' style={{ verticalAlign: 'middle' }} />
                <span style={{ paddingLeft: 10, verticalAlign: 'middle' }}>{text}</span>
            </div>
        );
    }

    // state variables
    const [openInvitationDialog, setOpenInvitationDialog] = useState(false);
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [snackbarSeverity, setSnackbarSeverity] = useState();
    const [statusMessage, setStatusMessage] = useState('');
    const [openEditDialog, setOpenEditDialog] = useState(false);
    const [selectedUser, setSelectedUser] = useState({});

    function handleSubmit([success, message]) {
        if (success) {
            setDataChanged(true);
            setSnackbarSeverity('success');
        } else {
            setSnackbarSeverity('error');
        }

        setStatusMessage(message);
        setOpenInvitationDialog(false);
        setOpenEditDialog(false);
        setOpenSnackbar(true);
    }

    function handleEditUser(user) {
        setSelectedUser(user);
        setOpenEditDialog(true);
    }

    return (
        <ThemeProvider theme={theme}>
            <div style={{ paddingTop: 60, paddingLeft: 150, paddingRight: 150, }}>
                <div className={classes.header}>
                    <h1>Team</h1>
                    <OutlinedButton onClick={() => setOpenInvitationDialog(true)}>Invite</OutlinedButton>
                </div>

                {/* dialog box to invite members */}
                <InvitationDialog
                    token={token}
                    open={openInvitationDialog}
                    onSubmit={handleSubmit}
                    onBackdropClick={() => { setOpenInvitationDialog(false) }}
                />

                {/* status msg of invitation */}
                <Snackbar
                    open={openSnackbar}
                    message={statusMessage}
                    autoHideDuration={3000}
                    onClose={() => setOpenSnackbar(false)}
                    anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                >
                    <Alert severity={snackbarSeverity} variant='filled'>
                        {statusMessage}
                    </Alert>
                </Snackbar>

                {/* edit user dialog */}
                {
                    role === 'admin'
                    && <EditUserDialog
                        teamName={teamName}
                        selectedUser={selectedUser}
                        token={token}
                        open={openEditDialog}
                        onSubmit={handleSubmit}
                        onBackdropClick={() => setOpenEditDialog(false)}
                    />

                }

                {/* table */}
                <div>
                    <Table className={classes.table}>
                        <TableHead>
                            <TableRow variant='head'>
                                {headers.map((value) =>
                                    <TableCell
                                        key={value}
                                        style={{
                                            fontFamily: 'Raleway-Bold',
                                            color: 'rgb(30,30,30)',
                                            fontSize: 14,
                                        }}
                                    >
                                        {value[0].toUpperCase() + value.slice(1)}
                                    </TableCell>)}
                            </TableRow>
                        </TableHead>

                        <TableBody style={{ fontSize: 15 }}>
                            {tableData.map((row, index) =>
                                <TableRow hover
                                    key={index}
                                    onClick={(_) => (role === 'admin') ? handleEditUser(row) : {}}
                                    style={{
                                        cursor: 'pointer',
                                    }}
                                >
                                    <TableCell
                                        style={{
                                            fontFamily: 'Raleway-Bold',
                                        }}
                                    >
                                        <NameComponent
                                            image={require('../img/img1.png')}
                                            text={row['name']}
                                        />
                                    </TableCell>
                                    <TableCell>{row['email']}</TableCell>
                                    <TableCell>{row['role']}</TableCell>
                                    <TableCell><Chip
                                        label={row['status']}
                                        style={row['status'].toLowerCase() === 'accepted'
                                            ? statusAcceptedStyle
                                            : statusDefaultStyle
                                        }
                                    /></TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </div>
            </div>
        </ThemeProvider>
    );
}
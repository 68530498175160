import {db} from '../firebase/index';
import Axios from "axios";
import {teamInviteUrl} from "./endpoints";

let success = false;
let msg = '';

export async function createTeam(teamName, user) {
    try {
        await db.collection('users').doc(user.uid).update({
            [`teams.${teamName}`]: "admin"
        });

        await db.collection('teams').doc(teamName).set({});

        await db.collection('teams').doc(teamName)
            .collection('members').doc(user.uid).set({
                name: user.name ?? '',
                email: user.email,
                role: 'admin',
                status: 'accepted',
                uid: user.uid,
            });

        success = true;
        msg = '';
    } catch (e) {
        msg = e.message;
    }

    return {success, msg};
}

export async function inviteToTeam(team, emails, token, name) {
    let config = {
        headers: {
            'Authorization': `Bearer ${token}`
        }
    };

    let data = {
        emails: emails,
        team: team,
        name: name,
    }

    try {
        await Axios.post(teamInviteUrl, data, config);
        success = true;
        msg = 'Invitation email sent';
    } catch (e) {
        msg = e.message;
    }

    return {success, msg};
}

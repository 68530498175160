import React, {Component} from 'react';
import {HashRouter, Route} from "react-router-dom";
import './App.css';
import Home from './home';
import {auth} from './firebase/index';
import LandingPage from "./landing";

class App extends Component {
    constructor(props) {
        super(props);
        this.state = {
            user: null,
        };

        this.authListener = this.authListener.bind(this);
    }

    componentDidMount() {
        this.authListener();
    }

    authListener() {
        let unsubscribe = auth.onAuthStateChanged(
            (user) => {
                    this.setState({user: user});
                    unsubscribe();
            },
        );
    }

    render() {
        return (
            <HashRouter>
                {this.state.user
                    ?
                    <Route render={(routeProps) =>
                        <Home {...routeProps}
                              user={this.state.user}
                              authListener={this.authListener}
                        />}
                    />
                    : <Route render={(props) =>
                        <LandingPage {...props}
                            authListener={this.authListener}
                        />}
                    />
                }
            </HashRouter>
        )
    }

}


export default App; 
import React, { useState } from 'react'
import EyeOpenUrl from './eye.svg'
import EyeClosedUrl from './eye_closed.svg'
import './form.css'

const FormField = ({
    label,
    onChange,
    placeholder,
    name,
    error,
    value,
    readOnly,
    type,
}) => {

    let [visible, setVisible] = useState(false)

    return (
        <div
            style={{

                padding: '12px 0px',
            }}
        >
            <div
                style={{
                    fontWeight: '500',
                    fontSize: '12px',
                    lineHeight: '24px',
                    letterSpacing: '0.23px'
                }}
            >
                {label}
            </div>
            <div style={{

                position: 'relative',
            }}>
                <input type={
                    visible ? 'text' :
                        type ?? 'text'
                }
                    disabled={readOnly}
                    placeholder={placeholder ?? label}
                    value={value}
                    style={{
                        backgroundColor: 'transparent',
                        fontFamily: 'RaleWay-Regular',
                        fontWeight: '500',
                        outline: 'none',
                        fontSize: '16px',
                        lineHeight: '24px',
                        letterSpacing: 0,
                        color: 'rgb(30,30,30)',
                        height: '40px',
                        width: '100%',
                        border: 'none',
                        borderBottom: '1px solid rgba(48, 48, 48, 0.2)'
                    }}

                    onChange={onChange}
                />
                {
                    type === 'password' &&

                    <img alt='' src={`${visible ? EyeOpenUrl : EyeClosedUrl}`}
                        style={{
                            position: 'absolute',
                            right: 8,
                            top: visible ? 8 : 16,
                            width: 20,
                            height: 20,
                            cursor: 'pointer',

                        }}
                        onClick={() => setVisible(!visible)}
                    />
                }
            </div>
            <div style={{
                color: 'red',
                fontSize: 13,
                paddingTop: 4
            }}>
                {error}
            </div>
        </div>
    )

}

const SignInForm = ({

    title,
    subtitle,
    fields = [],
    submitting,
    submitText,
    onSubmit,
    validation_errors = {},
    initial_values = {},
    footer = []

}) => {

    let [values, setValues] = useState(initial_values ?? {})

    return (
        <div
            style={{
                marginRight: 70,
                marginTop: -10,
                width: '361px',
                fontFamily: 'RaleWay-Regular',
                backgroundColor: 'transparent',
                padding: 32,
            }}
        >
            <div
                style={{
                    fontFamily: 'Raleway-Bold',
                    fontSize: '24px',
                    letterSpacing: 0,
                }}
            >
                {
                    title
                }
            </div>
            <div
                style={{
                    paddingTop: 8,
                    fontSize: '13px',
                    lineHeight: '23px',
                    color: 'rgb(54, 54 ,54)'
                }}
            >
                {
                    subtitle
                }
            </div>
            <form

                onSubmit={(e) => {
                    let data = { ...(values ?? {}) }
                    onSubmit(data)
                    e.preventDefault();
                }}
            >
                {
                    fields.map((field) => <FormField
                        key={field.name}
                        {...field}
                        error={validation_errors?.[field.name]}
                        value={values[field.name]}
                        onChange={(e) => setValues({
                            ...values,
                            [field.name]: e.target.value
                        })}

                    />)
                }
                <div
                    style={{ height: 12 }}
                />
                <FormButton

                    loading={submitting}
                    text={submitText}
                />
            </form>

            <div style={{
                display: 'flex',
                justifyContent: 'space-between',
                paddingTop: '24px',
            }}>
                {
                    footer.map((item, index) => <div
                        key={index}
                        style={{
                            fontSize: '13px',
                            lineHeight: '23px',
                            cursor: 'pointer'
                        }}
                        onClick={item.onClick}
                    >
                        {
                            item.text
                        }
                    </div>)
                }
            </div>
        </div>
    )
}

export default SignInForm;

const FormButton = ({
    text,
    loading,
    onClick,
}) => {
    return (

        <button className="button" type={'submit'}

            style={{
                textTransform: 'none',
                width: '100%',
                borderRadius: '6pt',
                height: '50px',
                backgroundColor: `rgba( 0, 0, 0, 
           ${loading ? 0.4 : 0.92}
            )`,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                color: 'white',
                fontSize: '16px',
                lineHeight: '24px',
                fontWeight: 'bold',
                cursor: 'pointer',
                border: 'none',
                outline: 'none'
            }}

            onClick={!loading && onClick}
        >
            {
                loading ?
                    <div className={'fa fa-spinner fa-spin'} />
                    :
                    text
            }

        </button>
    )
}
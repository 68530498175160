// src/firebase.js

// Firebase App (the core Firebase SDK) is always required and must be listed first
import firebase from 'firebase';

// Add the Firebase products that you want to use
import 'firebase/firestore';

// TODO: Replace the following with your app's Firebase project configuration
const firebaseConfig = {
  apiKey: "AIzaSyDbswRcKKd2NOHRyenCQc527yR7xOMfmnE",
  authDomain: "pauseable-e4aa1.firebaseapp.com",
  databaseURL: "https://pauseable-e4aa1.firebaseio.com",
  projectId: "pauseable-e4aa1",
  storageBucket: "pauseable-e4aa1.appspot.com",
  messagingSenderId: "254649106396",
  appId: "1:254649106396:web:be9ac059b16ca820e2d919",
  measurementId: "G-GX6ZEH11KV"
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);


export const db = firebase.firestore();
export const auth = firebase.auth();

export default {
  firebase
}
import React from 'react';
import { useState } from 'react';

// key code constants
const BACKSPACE = 8;
const LEFT_ARROW = 37;
const RIGHT_ARROW = 39;
const DELETE = 46;
// const SPACEBAR = 32;

const OtpInput = ({
    length = 6,
    onChange,
    hasError = true,
}) => {

    // const [activeInput, setActiveInput] = useState(0);
    const [otpValue, setOtpValue] = useState(Array(length));

    const inputs = [];
    for (let i = 0; i < length; ++i) {
        inputs.push(<SingleInputBox
            key={i}
            isFirstChild={i === 0}
            isLastChild={i === length - 1}
            outlineColor= {hasError ? 'rgba(255, 94, 94, 0.5)' : 'rgb(240,240,240)'}
            onChange={(val) => {
                setOtpValue(() => {
                otpValue[i] = val;
                return otpValue;
            })

            onChange(otpValue.join(''));
        }}
        />)
    }

    return (
        <div
            style={{
                display: 'flex',
                justifyContent: 'space-between',
                padding: '10px 0px',
                width: '100%',
            }}
        >
            {inputs}
        </div>
    );
}

export default OtpInput;

const SingleInputBox = ({
    isFirstChild,
    isLastChild,
    onChange,
    outlineColor,
}) => {

    const [value, setValue] = useState('');

    function setValueAndPropagate(value) {
        setValue(value);
        if(onChange) onChange(value);
    }

    function handleOnChange(event) {
        const regex = /^[0-9]$/;
        let val = event.target.value;
        if (val === '' || regex.test(val)) {
            setValueAndPropagate(val);
            focusNext(event.target);
        }
    }

    function onKeyDown(event) {
        if (event.keyCode === BACKSPACE || event.key === 'Backspace') {
            event.preventDefault();
            if(value) setValueAndPropagate('');
            focusPrevious(event.target);
        } else if (event.keyCode === DELETE || event.key === 'Delete') {
            event.preventDefault();
            setValueAndPropagate('');
        } else if (event.keyCode === LEFT_ARROW || event.key === 'ArrowLeft') {
            event.preventDefault();
            focusPrevious(event.target);
        } else if (event.keyCode === RIGHT_ARROW || event.key === 'ArrowRight') {
            event.preventDefault();
            focusNext(event.target);
        }
    }

    function focusNext(currentElement) {
        if (!isLastChild) {
            currentElement.nextSibling.focus();
        }
    }

    function focusPrevious(currentElement) {
        if (!isFirstChild) {
            currentElement.previousSibling.focus();
        }
    }

    return (
        <input
            value={value}
            onChange={handleOnChange}
            autoFocus={isFirstChild}
            onKeyDown={onKeyDown}
            style={{
                width: '80px',
                height: '80px',
                fontFamily: 'Raleway-Bold',
                fontSize: 24,
                textAlign: 'center',
                color: 'rgb(24,24,24)',
                boxSizing: 'border-box',
                border: `2px solid ${outlineColor}`,
                borderRadius: 3,
                // caretColor: 'transparent',
            }}
        />
    );
}
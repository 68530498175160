import React from 'react';

const StepIndicator = ({
    length = 3,
    currentIndex = 0,
}) => {
    let color = 'rgb(255, 161, 113)';

    if (currentIndex >= length) {
        currentIndex = length - 1;
    }
    else if (currentIndex < 0) {
        currentIndex = 0;
    }
    
    return (<div
        style={{
            display: 'flex',
        }}
    >
        {Array.apply(null, { length: length }).map((_, index) =>
            <div
                key={index}
                style={{
                    height: 3,
                    width: 40,
                    backgroundColor: color,
                    opacity: index === currentIndex ? 1 : 0.3,
                    margin: '0 2px',
                    transitionDuration: '0.5s',
                }} />
        )}
    </div>);
}

export default StepIndicator;
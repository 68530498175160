import React from 'react';
import StepIndicator from '../components/step_indicator';
import DoubleSegmentButton from './double_segment_button';
import {useState} from 'react';
import FormButton from '../components/form_button';
import InputField from '../components/input_field';
import OtpInput from '../components/otp_input';

import {IconButton} from '@material-ui/core';
import {createAccount, login, verifyCode} from "../api_services/account";
import {createTeam, inviteToTeam} from "../api_services/team";

const SignUpSection = ({
                           style, // style for outer container
                           authListener, // for auth change
                       }) => {

    const numberOfSteps = 4 - 1; // index starts at 0
    const [step, setStep] = useState(0);

    const [isNewUser, setIsNewUser] = useState(true);
    const [loading, setLoading] = useState(false);

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [otpCode, setOtpCode] = useState('');
    const [teamName, setTeamName] = useState('');
    const [invitedEmail, setInvitedEmail] = useState('');
    const [invitedEmailsList, setInvitedEmailsList] = useState([]);

    const [error, setError] = useState('');

    const [user, setUser] = useState({});
    const [token, setToken] = useState('');

    function validateEmail(email) {
        let re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(email);
    }

    function validatePassword(password) {
        return password.length >= 6;
    }

    const newUserProps = [
        {
            title: 'Step 1: Your Information',
            subtitle: 'If you do not have an existing account',
            showSegmentedButton: true,
            inputs: [
                {
                    label: 'EMAIL',
                    value: email,
                    onChange: (e) => setEmail(e.target.value),
                },
                {
                    type: 'password',
                    label: 'PASSWORD',
                    value: password,
                    onChange: (e) => setPassword(e.target.value),
                },

            ],
            validator: () => {
                if (!(validateEmail(email) && validatePassword(password))) {
                    setError('Invalid email or password');
                    return false;
                }
                return true;
            },
            disableNext: (email === ''),
            onClick: async () => {
                let {user, success, msg} = await createAccount(email, password, true);
                setUser(user);
                if (user) {
                    let token = await user.getIdToken();
                    setToken(token);
                }
                return {success, msg};
            },
        },
        {
            title: 'Step 2: Verification Code',
            subtitle: 'Check your email for a verification code to confirm your account',
            inputs: [
                {
                    type: 'otp',
                    onChange: (value) => setOtpCode(value),
                },
            ],
            disableNext: (otpCode.length < 6),
            onClick: () => verifyCode(email, otpCode),
            action: <p
                style={{
                    margin: 'auto 0px',
                }}
            >
                <span>Didn't get the code? </span>
                <span style={{
                    fontFamily: 'Raleway-Bold',
                    cursor: 'pointer',
                }}>Send again?</span>
            </p>,
        },
    ];

    const existingUserProps = [
        {
            title: 'Step 1: Your Information',
            subtitle: 'If you have an existing account',
            showSegmentedButton: true,
            inputs: [
                {
                    label: 'EMAIL',
                    value: email,
                    onChange: (e) => setEmail(e.target.value),
                },
                {
                    type: 'password',
                    label: 'PASSWORD',
                    value: password,
                    onChange: (e) => setPassword(e.target.value),
                },
            ],
            validator: () => {
                if (!(validateEmail(email) && validatePassword(password))) {
                    setError('Invalid email or password');
                    return false;
                }
                return true;
            },
            disableNext: (email === '' || password === ''),
            onClick: async () => {
                let {user, success, msg} = await login(email, password);
                setUser(user);
                if (user) {
                    let token = await user.getIdToken();
                    setToken(token);
                }
                return {success, msg};
            },
            action: <p
                style={{
                    fontFamily: 'Raleway-Bold',
                    fontSize: 13,
                    lineHeight: '23px',
                    textAlign: 'right',
                    margin: 'auto 0px',
                    cursor: 'pointer',
                }}
            >
                Forgot password?
            </p>,
        },
        {},
    ];

    const nextStepsProps = [
        {
            title: 'Step 3: Name your team',
            inputs: [
                {
                    label: 'TEAM',
                    value: teamName,
                    onChange: (e) => setTeamName(e.target.value)
                }
            ],
            disableNext: (teamName === ''),
            onClick: () => createTeam(teamName, user),
        },
        {
            title: 'Step 4: Invite friends',
            inputs: [
                {
                    label: 'INVITE BY EMAIL',
                    placeholder: 'Email',
                    value: invitedEmail,
                    onChange: (e) => setInvitedEmail(e.target.value),
                    action: <p
                        style={{
                            position: 'absolute',
                            right: 0,
                            top: 12,
                            margin: 'auto 0px',
                            textAlign: 'center',
                            fontFamily: 'Raleway-Bold',
                            lineHeight: '22px',
                            fontSize: '14px',
                            cursor: 'pointer',
                        }}
                        onClick={() => {
                            setError('');
                            if (!validateEmail(invitedEmail)) {
                                setError('Invalid email')
                                return;
                            }
                                setInvitedEmailsList(emails => [...emails, invitedEmail]);
                                setInvitedEmail('');
                        }}
                    >
                        <span
                            style={{
                                fontFamily: 'Raleway-Medium',
                                fontSize: '16px',
                                lineHeight: '24px'
                            }}
                        >
                            +
                        </span>
                        Invite
                    </p>
                }
            ],
            nextButtonText: 'Start meditating with team',
            disableNext: (invitedEmailsList.length <= 0),
            onClick: async () => {
                return await inviteToTeam(teamName, invitedEmailsList, token);
            },
            anyChildren: invitedEmailsList.map((value, i) =>
                <EmailDisplayBox
                    key={i}
                    email={value}
                    onClose={() => {
                        let emails = [...invitedEmailsList];
                        emails.splice(i, 1);
                        setInvitedEmailsList(emails);
                    }}
                />
            ),
        },
    ];


    async function handleClickNext(func: Promise): void {
        setLoading(true);

        const {success, msg} = await func();
        if (step === numberOfSteps) {
            authListener();
        }

        if (success) {
            if (step < numberOfSteps) setStep(step + 1);
            setError('');
        } else {
            setError(msg);
        }
        setLoading(false);
    }

    function decideComponent() {
        let stepProps;
        let index = step;

        if (index < 2) {
            stepProps = isNewUser ? newUserProps : existingUserProps;
        } else {
            index = step - 2;
            stepProps = nextStepsProps;
        }

        let props = stepProps[index];

        if (Object.keys(props).length === 0) setStep(step + 1);
        else
            return (
                <>
                    {/* section title */}
                    <SectionHeader
                        title={props.title}
                        subtitle={props.subtitle}
                    />
                    {
                        props.showSegmentedButton
                        && <DoubleSegmentButton
                            label1='Create new account'
                            label2='Already have an account'
                            onClick1={() => setIsNewUser(true)}
                            onClick2={() => setIsNewUser(false)}
                        />
                    }

                    {/* input fields */}
                    {
                        props.inputs.map((input, i) =>
                            input.type === 'otp'
                                ? <OtpInput
                                    key={i}
                                    length={6}
                                    onChange={input.onChange}
                                    hasError={error}
                                />
                                : <InputField
                                    key={i}
                                    type={input.type}
                                    label={input.label}
                                    placeholder={input.placeholder}
                                    value={input.value}
                                    onChange={input.onChange}
                                    action={input.action}
                                    error={error ? (i === 0 ? error : ' ') : ''}
                                />
                        )
                    }

                    {
                        props.anyChildren
                    }

                    {/* section footer */}
                    <div
                        style={{
                            display: 'flex',
                            flexWrap: 'wrap',
                            justifyContent: 'space-between',
                            padding: '20px 0px'
                        }}
                    >
                        <FormButton
                            text={props.nextButtonText ?? 'Next'}
                            disabled={props.disableNext}
                            loading={loading}
                            onClick={() => {
                                // validate inputs
                                if (props.hasOwnProperty('validator')) {
                                    if (!props.validator())
                                        return;
                                }
                                handleClickNext(props.onClick)
                            }}
                        />

                        {props.action}

                    </div>
                </>
            );
    }

    return (
        <div
            style={{
                ...style,
                transitionDuration: '0.5s',
            }}
        >
            <TitleHeader/>

            <StepIndicator
                length={numberOfSteps + 1}
                currentIndex={step}
            />

            {decideComponent()}

        </div>
    );
}

export default SignUpSection;


const TitleHeader = () => {
    return (
        <div
            style={{
                marginBottom: 25,
            }}
        >
            <h1
                style={{
                    fontFamily: 'Raleway-Bold',
                    fontSize: 32,
                    margin: 0,
                }}
            >
                Create new Team
            </h1>
            <p
                style={{
                    margin: 0,
                    fontFamily: 'Raleway-Regular',
                    fontSize: 15,
                    lineHeight: '23px',
                }}
            >
                Share your practice and progress with the people you care
            </p>
        </div>
    );
}

const SectionHeader = ({
                           title,
                           subtitle,
                       }) => {
    return (
        <div
            style={{
                marginBottom: 10,
                marginTop: 10,
            }}
        >
            <h2
                style={{
                    fontFamily: 'Raleway-Bold',
                    fontSize: 24,
                    margin: 0,
                }}
            >
                {title}
            </h2>
            <p
                style={{
                    margin: 0,
                    fontFamily: 'Raleway-Regular',
                    fontSize: 15,
                    lineHeight: '23px',
                }}
            >
                {subtitle}
            </p>
        </div>
    );
}


const EmailDisplayBox = ({
                             email,
                             onClose,
                         }) => {
    return (
        <div
            style={{
                display: 'flex',
                justifyContent: 'space-between',
                borderBottom: '1px solid rgba(48,48,48,0.2)',
                padding: '10px 0px',
                // margin: 0,
                // width: '100%',
            }}
        >
            <p
                style={{
                    margin: 'auto 0px',
                    textAlign: 'left',
                    fontFamily: 'Raleway-Medium',
                    fontSize: 16,
                    lineHeight: '24px',
                }}
            >
                {email}
            </p>
            <IconButton
                style={{
                    width: 'auto',
                    height: 40,
                }}
                onClick={onClose}
            >
                <p
                    style={{
                        fontSize: 32,
                        margin: 'auto 0px',
                        textAlign: 'center',
                        fontFamily: 'Roboto',
                        fontWeight: '300',
                        cursor: 'pointer',
                    }}
                >
                    ×
                </p>
            </IconButton>
        </div>
    );
}

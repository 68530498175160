import React from 'react';
import './teamTable.css';
import Member from './members';
import { Markup } from 'interweave';

function getSpan(object, day) {
    try {
        if (object[day].totalSessions !== null && object[day].totalSessions !== undefined) {
            let spans = '';
            for (var i = 0; i < object[day].totalSessions; i++) {
                spans += '<span id="touch"></span>';
            }
            return spans;
        }
    } catch (err) {
        // console.log(err);
    }
}

function getPauses(object, day) {
    try {
        if (object[day].totalSessions !== null && object[day].totalSessions !== undefined) {
            return object[day].totalSessions;
        }
        else {
            return 0;
        }
    }
    catch (err) {
        return 0;
    }
}

function getDuration(object) {
    try {
        if (object.weekSummary.totalDuration !== null && object.weekSummary.totalDuration !== undefined) {
            return Math.floor(object.weekSummary.totalDuration / 60);
        }
        else {
            return 0;
        }
    } catch (err) {
        return 0;
    }
}
export function objectIntoTableData(object) {
    if (object !== null && object !== undefined) {
        return Object.keys(object).map(function (key, index) {
            var img;
            var name;
            if(object[key].name !== null && object[key].name !== undefined){
                name = object[key].name;
            }else{
                name = key.slice(0, 5);
            }
            if (key === 'ldBp8oUDD6h77inD0AdXxVcb7Uf1') {
                img = 'img2.png';
                // name = `Peng Cheng`;
            } else if (key === 'i0KaJrz1GSeyvNBbDelciKFIGlj2') {
                img = 'img1.png';
                // name = 'Jimmi Andersen'
            } else {
                img = 'img3.png'
            }
            return (
                <tr key={key}>
                    <td><Member source={require(`../img/${img}`)} name={name} rank={getPauses(object[key], 'weekSummary')} duration={getDuration(object[key])} /></td>
                    <td><center><Markup content={getSpan(object[key], 'monday')} /></center></td>
                    <td><center><Markup content={getSpan(object[key], 'tuesday')} /></center></td>
                    <td><center><Markup content={getSpan(object[key], 'wednesday')} /></center></td>
                    <td><center><Markup content={getSpan(object[key], 'thursday')} /></center></td>
                    <td><center><Markup content={getSpan(object[key], 'friday')} /></center></td>
                    <td><center><Markup content={getSpan(object[key], 'saturday')} /></center></td>
                    <td><center><Markup content={getSpan(object[key], 'sunday')} /></center></td>
                </tr>
            )
        });

    }
}

const TeamTable = memberData => {
    return (
        <div className='TableContainer'>
            <div id="TableHeading">Team</div>
            <div className="TeamTable">
                <table className="table" cellPadding="10" cellSpacing="10">
                    <tbody className="border">
                        <tr>
                            <th></th>
                            <th>Monday</th>
                            <th>Tusday</th>
                            <th>Wednesday</th>
                            <th>Thursday</th>
                            <th>Friday</th>
                            <th>Saturday</th>
                            <th>Sunday</th>

                        </tr>

                        {objectIntoTableData(memberData.memberData.response)}


                    </tbody>
                </table>
            </div>
            <div className="index">
                <span id="touch"></span> Mindful Touch &ensp;
                <span id="timer"></span> Timer
            </div>
        </div>
    );
};

export default TeamTable;
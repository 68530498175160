import React from 'react'
import CheckUrl from './check.svg'

const items = [
    {
        title:'14-day free trial',

    },
    {
        title:'Unlimited access to all practices',
    },
    {
        title:'Pause Insights™'
    },
    {
        title:'Pause Together™',
        subtitle:'with people you care'
    },
    {
        title:'Daily pause goals'
    },
    {
        title:'Exclusive soundscapes'
    },
    {
        title:'Dark mode'
    },
    {
        title:'Cancel anytime'
    }
]

// eslint-disable-next-line
const CheckCircle = ({})=><div
style={{display:'flex',
justifyContent:'center',
    alignItems:'center',
    width:28,
    height:28,
    borderRadius:28,
    backgroundColor:'rgba(48,48,48,0.1)'
}}
>
    <img alt='checkUrl' src={CheckUrl} style={{width:14,height:10}}/>
</div>

const GetStarted = ({
title='Daily Mindfulness'
}) => {

    return(
        <div style={{
            padding:'100px 0px',
            fontFamily:'Raleway-Regular',
            color:'rgb(48,48,48)'
        }}>
        <div
        style={{
            fontFamily: 'Raleway-Bold',
            fontSize:'24px',
            // fontWeight:'800',
            letterSpacing:0,
        }}
        >
            {title}
        </div>
            <div
            style={{
                fontSize:'14px',
                lineHeight:'21px',
                letterSpacing:0,
                paddingBottom: '15px',
                paddingTop: 2,
            }}
            >
                Unlock all possibilities of meditation
            </div>
            {
                items.map((item)=><div
                key={item.title}
                style={{
                    fontWeight:'600',
                    fontSize:'14px',
                    lineHeight:'22px',
                    padding:'12px 0px',
                    display:'flex',
                    alignItems:'center'
                }
                }
                >
                    <CheckCircle/>
                    <div style={{paddingLeft:12}}>
                    {
                        item.title
                    }
                    </div>
                    {
                        item.subtitle &&
                        <div style={{
                            color:'rgb( 141 ,144 ,147)',
                            fontWeight:'500',
                            paddingLeft:5,
                        }}>
                            {item.subtitle}
                        </div>
                    }
                </div>)
            }
        </div>
    )
}

export default GetStarted;
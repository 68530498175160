import React from 'react'
import './form.css'

const ArrowButton = ({ onClick, children }) =>
    <div
        style={{
            display: 'flex',
            alignItems: 'center',
            padding: '0px 20px',
            height: '40px',
            borderRadius: 4,
            border: '2px solid black',
            fontFamily: 'Raleway-Regular',
            fontWeight: '700',
            fontSize: '14px',
            lineHeight: '22px',
            cursor: 'pointer'
        }}
        onClick={onClick}
    >
        {children}
        <div>
            &nbsp;🡪
    </div>
    </div>
// eslint-disable-next-line
const Logo = ({ }) => <img src={require('./logo.png')} />
// eslint-disable-next-line
{/* <div style={{
    width: 28,
    height: 28,
    minHeight: 28,
    minWidth: 28,
    display: 'flex',
    justifyContent: 'center',
    border: '2.8pt solid black',

    borderRadius: '100%',
    alignItems: 'center'
}}>
    <div
        style={{
            width: 20,
            height: 20,
            minHeight: 20,
            minWidth: 20,
            backgroundColor: 'black',
            borderRadius: '100%',
        }}
    />

</div> */}

const SignInHeader = ({ 
    onButtonClick,
}) => {

    return (
        <div style={{
            display: 'flex',
            width: '100%',
            justifyContent: 'space-between',
            alignItems: 'center',
            paddingTop: 32
        }}>
            <a href={'https://pauseable.com/'}>
                <Logo />
            </a>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <div
                    style={{
                        fontFamily: 'Raleway-Regular',
                        fontWeight: '500',
                        fontSize: '14px',
                        lineHeight: '22px',
                        padding: '0px 20px'
                    }}
                >
                    Try Pause with your team
                </div>
                <ArrowButton
                    onClick={onButtonClick}
                >
                    For teams
                </ArrowButton>

            </div>
        </div>
    );
}

export default SignInHeader;
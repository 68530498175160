import {auth, db} from '../firebase/index';
import Axios from "axios";
import {verificationCodeUrl} from './endpoints';

/**
 *
 * @param email
 * @param password
 * @param sendCode
 * @returns {Promise<{msg: string, success: boolean, user: Object}>}
 */
export async function createAccount(email, password, sendCode = false) {
    let user;
    let success = false;
    let msg = '';

    try {
        let response = await auth.createUserWithEmailAndPassword(email, password);
        user = response.user;
        await db.collection('users').doc(user.uid).set({
            providerId: 'password',
            subscriptionPlan: 'free',
        });
        if(sendCode) {
            await sendVerificationCode(email);
        }

        success = true;
        msg = 'Account created';
    } catch (e) {
        msg = e.message;
    }

    return {success, msg, user};
}

/**
 *
 * @param email
 * @param password
 * @returns {Promise<{msg: string, success: boolean, user: Object}>}
 */
export async function login(email, password) {
    let user;
    let success = false;
    let msg = '';

    try {
        let response = await auth.signInWithEmailAndPassword(email, password);
        user = response.user;
        success = true;
        msg = 'Login ';
    } catch (e) {
        msg = e.message;
    }

    return {success, msg, user};
}

export async function sendVerificationCode(email) {
    let success = false;
    let msg = '';

    try {
        await Axios.get(verificationCodeUrl,
            {
                params: {
                    email: email
                }
            });
        success = true;
        msg = 'Code sent';
    } catch (e) {
        msg = e.message;
        throw e;
    }

    return {success, msg};
}

export async function verifyCode(email, code) {
    let success = false;
    let msg = '';

    try {
        await Axios.post(
            verificationCodeUrl,
            {
                email: email,
                code: code,
            }
        );
        success = true;
        msg = 'Code verified';
    } catch (e) {
        msg = e.message;
    }

    return {success, msg};
}
import React from 'react';
import Header from './header';
import SideContent from './side_contents';
import SignUpSection from './sign_up_section';

import RectangleUrl from './side.svg';

const CreateTeamPage = ({
    history, // for pushing routes used by 'react-router-dom'
    authListener,
}) => {
    return (
        <div
            style={{
                position: 'absolute',
                width: '100%',
                height: '100%',
                overflowY: 'auto',
                margin: 0,
                padding: '40px',
                boxSizing: 'border-box',
                backgroundImage: `url(${RectangleUrl})`,
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'left',
                backgroundSize: 'contain, cover'
            }}
        >
            {/* header */}
            <Header
                onCloseButtonClick={() => history.goBack()}
            />

            {/* main */}
            <main
                style={{
                    display: 'flex',
                    marginTop: 15,
                    justifyContent: 'space-between',
                }}
            >
                <SideContent
                    style={{
                        flexBasis: '40%',
                    }}
                />
                <div style={{ flexBasis: '10%' }} />

                <SignUpSection
                    style={{
                        flexBasis: '40%',
                    }}
                    authListener={authListener}
                />

                <div style={{ flexBasis: '20%' }} />

            </main>

            {/* footer */}
            <div
                style={{
                    position: 'fixed',
                    bottom: 25,
                    right: 25,
                }}
            >
                <img 
                src={require('../img/intercom.png')} 
                alt='Floating button'
                style={{
                    width: 60,
                    height: 'auto',
                }}
                />
            </div>
        </div>
    );
}

export default CreateTeamPage;

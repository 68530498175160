import React from 'react';
import './members.css';
// import { Toolbar, IconButton, SvgIcon } from '@material-ui/core';

const Member = props => {
    return (
        <div id="container">
            <div id="children1">
                <img id="profile" src={props.source} alt="profile" />
            </div>
            <div id="children2">
                {props.name}
                <div id="children3">
                    {props.duration} mins #{props.rank}
                </div>
            </div>

        </div>
    );
};

export default Member;
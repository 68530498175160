import React from 'react'
import ellipseUrl from './ellipse.svg'
import SignInHeader from "./header";
import SignInFooter from "./footer";
import GetStarted from "./get_started";
import SignInForm from "./form";



const SignInPage = ({
    type = 'login', //type is login or sign-up
    onSubmit,   //on form submit function
    history,    //For pushing routes (react-router-dom) provided by <Route/>
    submitting,  //bool value for form submitting
    validation_errors,
    initial_values
}) => {


    const signInProps = {
        gettingStartedTitle: 'Daily Mindfulness',
        title: "Signin to Pause",
        subtitle: 'Create account to start using interactive meditation, mindful movement and intelligent guidance.',
        submitText: 'Sign in',
        footer: [
            {
                text: <div>`Don't have an account? <strong>Sign up</strong>`</div>,
                onClick: () => {
                    history.push('/sign-up')
                }
            },
            {
                text: <strong>Forgot password?</strong>,

                onClick: () => {
                    history.push('/forgot-password')
                }
            }
        ],
        fields: [
            {
                name: 'email',
                label: 'EMAIL',
                placeholder: 'Email'

            },
            {
                name: 'password',
                label: 'PASSWORD',
                placeholder: 'Password',
                type: 'password'
            }
        ]

    }

    const forgotPasswordProps = {
        gettingStartedTitle: 'Daily Mindfulness',
        title: "Reset Password",
        subtitle: 'Enter your email address to reset your password',
        submitText: 'Submit',
        footer: [
            {
                text: <div><strong>Return to Sign in</strong></div>,

                onClick: () => history.push('/signin')
            }
        ],
        fields: [
            {
                name: 'email',
                label: 'EMAIL',
                placeholder: 'Email'

            }
        ]

    }

    const verifyCodeProps = {
        gettingStartedTitle: 'Daily Mindfulness',
        title: "Verify Reset Code",
        subtitle: 'Enter the reset code you received in the email',
        submitText: 'Verify',
        footer: [
            {
                text: <div><strong>Return to Sign in</strong></div>,

                onClick: () => history.push('/signin')
            }
        ],
        fields: [
            {
                name: 'email',
                label: 'EMAIL',
                placeholder: 'Email',
                readOnly: true

            },
            {
                name: 'code',
                label: 'CODE',
                placeholder: 'Code'

            }
        ]

    }
    const changePasswordProps = {
        gettingStartedTitle: 'Daily Mindfulness',
        title: "Set New Password",
        subtitle: 'Enter a new password for your account.',
        submitText: 'Submit',
        footer: [
            {
                text: <div><strong>Return to Sign in</strong></div>,

                onClick: () => history.push('/signin')
            }
        ],
        fields: [
            {
                name: 'email',
                label: 'EMAIL',
                placeholder: 'Email',
                readOnly: true

            },
            {
                name: 'newPassword',
                label: 'NEW PASSWORD',
                placeholder: 'Password',
                type: 'password'

            }
        ]

    }


    const signUpProps = {
        gettingStartedTitle: 'Daily Mindfulness',
        title: "Create an account",
        subtitle: 'Create account to start using interactive meditation, mindful movement and intelligent guidance.',
        submitText: 'Sign up',
        footer: [
            {
                text: <div>`Have an account? <strong>Sign in</strong>`</div>,
                onClick: () => history.push('/login')
            }
        ],
        fields: [
            {
                name: 'name',
                label: 'NAME',
                placeholder: 'Name',

            },
            {
                name: 'email',
                label: 'EMAIL',
                placeholder: 'Email'

            },
            {
                name: 'password',
                label: 'PASSWORD',
                placeholder: 'Password',
                type: 'password'
            }
        ]

    }

    const typePropMap = {
        'login': signInProps,
        'sign-up': signUpProps,
        'forgot-password': forgotPasswordProps,
        'verify-code': verifyCodeProps,
        'change-password': changePasswordProps
    }

    return (
        <>
            <div
                style={{
                    position: 'absolute',
                    width: '100%',
                    height: '100%',
                    overflowY: 'auto',
                    backgroundColor: 'rgba(181, 218, 180)',

                }}
            >
                <div
                    style={{
                        position: 'absolute',
                        width: '100%',
                        height: '92%',
                        minHeight: 700,
                        backgroundImage: `url(${ellipseUrl})`,
                        backgroundRepeat: 'no-repeat',
                        backgroundSize: '.8',
                        backgroundPosition: 'right'
                    }}
                />
                <div style={{
                    position: 'absolute', width: '100%',
                    // height:'100%',
                    minHeight: 792
                }}>
                    <div style={{
                        padding: '0px 150px',
                        maxWidth: 1200,
                        margin: 'auto'
                    }}>
                        <SignInHeader onButtonClick={() => history.push('/create-team')}/>
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <GetStarted
                                title={(typePropMap[type])?.gettingStartedTitle}
                            />
                            <SignInForm
                                {...(typePropMap[type])}

                                submitting={submitting}
                                onSubmit={onSubmit}
                                validation_errors={validation_errors}
                                initial_values={initial_values}

                            />
                        </div>
                        <div
                            style={{
                                position: 'absolute',
                                bottom: 0
                            }}
                        >
                            <SignInFooter />
                        </div>
                    </div>
                </div>

            </div>


        </>
    )
}

export default SignInPage;

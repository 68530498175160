import { Button, CircularProgress, Dialog, IconButton, MenuItem, Select, TextField } from '@material-ui/core';
import { createMuiTheme, makeStyles, ThemeProvider } from '@material-ui/core/styles';
import React, { useEffect, useState } from 'react';
import { db } from '../firebase/index';


const useStyles = makeStyles(() => ({
    select: {
        minWidth: 200,
    },
    dialog: {
        width: 460,
        // height: 460,
        borderRadius: 6,
        padding: 50,
        fontFamily: "Raleway-Medium",
        boxSizing: "border-box",
    },
    label: {
        fontWeight: 600,
        letterSpacing: 0,
        lineHeight: '24pt',
        fontSize: 12,
        color: 'rgb(30,30,30)',
        marginBottom: 5,
    },

    button: {
        height: 50,
        fontFamily: "Raleway-Bold",
    }
}))


const theme = createMuiTheme({
    palette: {
        primary: {
            main: 'rgb(35,136,211)' //your color
        },
        secondary: {
            main: 'rgb(211,35,117)'
        }
    }
});

export function EditUserDialog({
    open,
    onBackdropClick,
    onSubmit,
    teamName,
    selectedUser,
}) {
    const classes = useStyles();

    const [user, setUser] = useState({});
    const [name, setName] = useState('');
    const [emailError, setEmailError] = useState(''); // to validate email
    const [showDelete, setShowDelete] = useState(false);
    const [loading, setLoading] = useState(false); // submit status

    // function emailValidated(email) {
    //     var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    //     return re.test(email);
    // }

    function clearState() {
        onBackdropClick();
        setEmailError('');
        setLoading(false);
        setShowDelete(false);
    }

    // initialize user data
    useEffect(() => {
        setUser(selectedUser);
        setName(selectedUser.name);
    }, [selectedUser])

    function handleEditUser() {
        setLoading(true);
        let success = false;
        let msg;

        if (user.uid) {
            db.collection('teams').doc(teamName).collection('members').doc(user.uid).update(user)
                .then(response => {
                    success = true;
                    msg = `Member updated successfully`;
                })
                .catch((err) => {
                    success = false;
                    msg = 'Cannot update member'
                })
                .finally(() => {
                    setLoading(false);
                    onSubmit([success, msg]);
                })
        } else if (user.inviteId) {
            Promise.all([
                db.collection('inviteCodes').doc(user.inviteId).update(user),
                db.collection('teams').doc(teamName).collection('invites').doc(user.inviteId).update(user),
            ])
                .then(() => {
                    success = true;
                    msg = 'Invitation updated successfully';
                })
                .catch(err => {
                    console.error(err);
                    success = false;
                    msg = 'Cannot update invitation';
                })
                .finally(() => {
                    setLoading(false);
                    onSubmit([success, msg]);
                })
        }
    }

    function handleDeleteUser() {
        setLoading(true);
        let success = false;
        let msg;

        if (user.uid) {
            db.collection('teams').doc(teamName).collection('members').doc(user.uid).delete()
                .then(response => {
                    success = true;
                    msg = `Member ${name} deleted successfully`;
                })
                .catch((err) => {
                    success = false;
                    msg = `${name} cannot be deleted`;
                })
                .finally(() => {
                    setLoading(false);
                    onSubmit([success, msg]);
                })
        } else if (user.inviteId) {
            Promise.all([
                db.collection('inviteCodes').doc(user.inviteId).delete(),
                db.collection('teams').doc(teamName).collection('invites').doc(user.inviteId).delete(),
            ])
                .then(() => {
                    success = true;
                    msg = 'Invitation deleted successfully';
                })
                .catch(err => {
                    success = false;
                    msg = 'Cannot delete invitation';
                })
                .finally(() => {
                    setLoading(false);
                    onSubmit([success, msg]);
                })
        }
    }

    let editContents = (
        <div>
            <IconButton
                style={{ position: 'relative', left: 320, }}
                onClick={(_) => { clearState(); }}
            >
                <img src={require('../img/close.png')} width="28pt" height="auto" alt="icon" draggable="false" />
            </IconButton>
            <h2 style={{ fontFamily: "Raleway-Bold", fontSize: 24, marginTop: 0 }}>Edit {name}</h2>
            <p style={{ fontFamily: "Raleway-Regular", fontSize: 13, lineHeight: 2 }}>Edit user</p>

            <form>
                <p className={classes.label}>NAME</p>
                <TextField
                    placeholder='Name'
                    value={user.name}
                    onChange={(event) => setUser({ ...user, name: event.target.value })}
                    fullWidth
                />

                <p className={classes.label}>EMAIL</p>
                <TextField
                    placeholder='Email'
                    required={true}
                    helperText={emailError}
                    error={emailError !== ''}
                    value={user.email}
                    onChange={(event) => setUser({ ...user, email: event.target.value })}
                    fullWidth
                />

                <p className={classes.label}>Permission</p>
                <Select
                    value={user.role}
                    onChange={(event) => setUser({ ...user, role: event.target.value })}
                    fullWidth
                >
                    <MenuItem value='admin'>Admin</MenuItem>
                    <MenuItem value='manager'>Manager</MenuItem>
                    <MenuItem value='member'>Member</MenuItem>
                </Select>

                <div
                    style={{
                        display: 'flex', justifyContent: 'center', marginTop: 25,
                    }}
                >
                    <div
                        style={{
                            flex: '1 1 0px',
                            textAlign: 'center',
                            margin: 'auto',
                        }}
                    ><span
                        style={{
                            fontWeight: 'bold',
                            fontSize: 13,
                            color: 'rgb(54,54,54)',
                            textDecoration: 'underline',
                            cursor: 'pointer',
                        }}
                        onClick={(_) => !loading ? setShowDelete(true) : {}}
                    >
                            Delete user?
                    </span>
                    </div>

                    <div
                        style={{
                            flex: '1 1 0px',
                            margin: 'auto',
                        }}
                    >
                        <ThemeProvider theme={theme}>
                            <Button
                                color='primary'
                                variant='contained'
                                onClick={(_) => handleEditUser()}
                                fullWidth
                                className={classes.button}
                            >
                                {loading === false
                                    ? 'Save'
                                    : <CircularProgress
                                        color='primary'
                                        style={{ color: 'white' }}
                                    />
                                }
                            </Button>
                        </ThemeProvider>
                    </div>
                </div>
            </form>



        </div>
    );

    let deleteContents = (
        <div
            style={{
                maxHeight: 245,
            }}
        >
            <h2 style={{ fontFamily: "Raleway-Bold", fontSize: 24, margin: 0 }}>Delete {name}?</h2>
            <p style={{ fontFamily: "Raleway-Regular", fontSize: 13, lineHeight: 2, margin: '8px 0px' }}>Are you sure you want to delete this user from?</p>

            <div style={{ display: 'flex', justifyContent: 'center', height: 50, marginTop: 30, }}>
                <div
                    style={{
                        flex: '1 1 0px',
                        textAlign: 'center',
                        margin: 'auto',
                    }}
                ><span
                    style={{
                        fontWeight: 'bold',
                        fontSize: 13,
                        color: 'rgb(54,54,54)',
                        textDecoration: 'underline',
                        cursor: 'pointer',
                        marginRight: 10,
                    }}
                    onClick={(_) => clearState()}
                >
                        Cancel
                    </span></div>

                <div
                    style={{
                        flex: '1 1 0px',
                        margin: 'auto',
                    }}
                >
                    <ThemeProvider theme={theme}>
                        <Button
                            color='secondary'
                            variant='contained'
                            onClick={(_) => handleDeleteUser()}
                            fullWidth
                            className={classes.button}
                        >
                            {loading === false
                                ? 'Yes, delete'
                                : <CircularProgress
                                    color='primary'
                                    style={{ color: 'white' }}
                                />
                            }
                        </Button>
                    </ThemeProvider>
                </div>
            </div>
        </div>
    );

    return (
        <Dialog
            open={open}
            onBackdropClick={(_) => {
                clearState();
            }}
            classes={{ paper: classes.dialog }}
        >
            {showDelete ? deleteContents : editContents}
        </Dialog>
    );
}
import React from 'react';
import './dashboard.css';
import { db } from '../firebase/index';
import TeamTable from './teamTable';
import AggregateBox from './aggregates';
import { useState } from 'react';
import { useEffect } from 'react';

function getWeekNumber(date) {
    // Copy date so don't modify original
    date = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));
    // Set to nearest Thursday: current date + 4 - current day number
    // Make Sunday's day number 7
    date.setUTCDate(date.getUTCDate() + 4 - (date.getUTCDay() || 7));
    // Get first day of year
    var yearStart = new Date(Date.UTC(date.getUTCFullYear(), 0, 1));
    // Calculate full weeks to nearest Thursday
    var weekNo = Math.ceil((((date - yearStart) / 86400000) + 1) / 7);
    // Return array of year and week number
    // return [d.getUTCFullYear(), weekNo];
    if (weekNo < 10) {
        weekNo = '0' + String(weekNo);
    }
    return `${date.getUTCFullYear()}-${weekNo}`
}

// return current time required to set the document id of sessions subcollection
var today = new Date();
var week = getWeekNumber(today);
var previousWeek = getWeekNumber((new Date(today - 7 * 24 * 60 * 60 * 1000)));


var hours;
var min;
function getHoursandMin(seconds) {
    hours = Math.floor(seconds / 60 / 60);
    min = Math.floor((seconds / 60 / 60 - hours) * 60);
}

const Dashboard = ({
    user,
    token,
    currentTeam,
}) => {
    const [memberData, setMemberData] = useState({});
    const [teamData, setTeamData] = useState({ totalSession: 0, totalDuration: 0 });
    const [previousWeeksTeamData, setPreviousWeeksTeamData] = useState({ totalSession: 0, totalDuration: 0 });

    // get user data
    useEffect(() => {
        let headers = { "Content-Type": "application/json" };
        if (currentTeam) {
            headers["Authorization"] = `Bearer ${token}`;

            fetch(
                `https://us-central1-pauseable-e4aa1.cloudfunctions.net/pauseTeams?team=${currentTeam}`,
                { headers, }
            )
                .then(res => res.json())
                .then((data) => {
                    setMemberData(data.success.data);
                });

            db.collection('teams').doc(currentTeam).collection('aggregates').doc('weekly').collection(`${week}`).doc('weekSummary').get().then(docSnap => {
                if (docSnap.exists) {
                    setTeamData(docSnap.data());
                }
            });

            db.collection('teams').doc(currentTeam).collection('aggregates').doc('weekly').collection(previousWeek.toString()).doc('weekSummary').get().then(docSnap => {
                    if (docSnap.exists) {
                        setPreviousWeeksTeamData(docSnap.data());
                    }
                });
        }

    }, [user, token, currentTeam]);


    getHoursandMin(teamData.totalDuration);

    let sessionChange = Math.floor((teamData.totalSession - previousWeeksTeamData.totalSession) / teamData.totalSession * 100);
    let durationChange = Math.floor((teamData.totalDuration - previousWeeksTeamData.totalDuration) / teamData.totalDuration * 100);

    let sessionChanger = isNaN(sessionChange) || !isFinite(sessionChange) ? '-' : Math.abs(sessionChange);
    let durationChanger = isNaN(durationChange) || !isFinite(durationChange) ? '-' : Math.abs(durationChange);

    var sessionArrow = "Up";
    var durationArrow = "Up";
    if (sessionChange < 1) {
        sessionArrow = "Down"
    }
    if (durationChange < 1) {
        durationArrow = "Down"
    }

    return (
        <div id="main">
            <h1 className="heading" draggable="false">Dashboard</h1>
            <div id="parentDiv">
                <div id="background">
                    <img id="blob" alt="" src={require('../img/blobs.png')} draggable="false" />
                </div>
                <div id="mainContainer">
                    <div id="child1" draggable="false">Week {week.substr(week.length - 2)}
                    </div>
                    <div>
                        <AggregateBox draggable="false" id="Box1"
                            heading="Pauses"
                            value={teamData.totalSession}
                            change={sessionChanger}
                            arrow={sessionArrow}
                        />
                        <AggregateBox draggable="false" id="Box2" heading="Duration" value={
                            <span style={{ fontFamily: "SFProDisplay-Medium", fontSize: "28px" }}>{hours}
                                <span style={{ fontFamily: "SFProDisplay-Bold", fontSize: "15px" }}>h</span> {min}
                                <span style={{ fontFamily: "SFProDisplay-Bold", fontSize: "15px" }}>m</span>
                            </span>}
                            change={durationChanger} arrow={durationArrow} />
                    </div>
                    <div id="child2">
                        <TeamTable memberData={memberData} teamName={currentTeam} />
                    </div>
                </div>
            </div>
        </div >
    );
}


export default Dashboard;
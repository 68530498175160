import React from 'react';
import './aggregates.css';

const AggregateBox = props => {
    return (
        <div id={props.id} className="Parent" >
            <link rel="stylesheet" href="https://use.fontawesome.com/releases/v5.13.0/css/all.css" />
            <link rel="stylesheet" href="https://use.fontawesome.com/releases/v5.13.0/css/v4-shims.css" />
            <div id="Heading">
                {props.heading}
            </div>
            <div id="Value">
                {props.value}
            </div>
            <div id ="arrowUp">
                <i id ={props.arrow} className="fas fa-angle-double-up" ></i>
                <div id="change">
                    {props.change}%
                    </div>
            </div>

        </div>
    );
};

export default AggregateBox;
import React from 'react';

const SideContent = ({
    style, // style for outer container
}) => {
    return (
        <div style={style}>
            <div
                style={{
                    marginRight: 70,
                }}
            >
                <h1
                    style={{
                        fontFamily: 'Raleway-Bold',
                        fontSize: 32,
                        margin: 0,
                    }}
                >
                    Pause Teams allows your friends, family and community to share
                    and track your mindfulness practice
                </h1>

                <br style={{ height: 25 }} />

                <p
                    style={{
                        fontFamily: 'Raleway-Medium',
                        fontSize: '18px',
                        lineHeight: '26px'

                    }}
                >
                    “We use Pause in our team to encourage mindful breaks during the day and stay at peak”
                    – Peng Cheng, Pauseable
                </p>

                
                    <img
                        alt = ""
                        src={require('./immage.png')}
                        srcSet={
                            `${require('./immage.png')} 400w,
                            ${require('./immage@2x.png')} 800w,
                            ${require('./immage@3x.png')} 1200w`
                        }
                        style={{
                            display: 'block',
                            width: '90%',
                            margin: 'auto',
                            marginTop: 50,
                        }}
                    />
            </div>
        </div>
    );
}

export default SideContent;
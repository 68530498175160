import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
    createMuiTheme,
    ThemeProvider
} from '@material-ui/core/styles';
import { Dialog, IconButton, Button, TextField, CircularProgress } from '@material-ui/core';
import Axios from 'axios';

const useStyles = makeStyles(() => ({
    select: {
        minWidth: 200,
    },
    dialog: {
        width: 460,
        // height: 460,
        borderRadius: 6,
        padding: 50,
        fontFamily: "Raleway-Medium",
        boxSizing: "border-box",
    },
    label: {
        fontWeight: 600,
        letterSpacing: 0,
        lineHeight: '24pt',
        fontSize: 12,
        color: 'rgb(30,30,30)',
        marginBottom: 5,
    },

    button: {
        height: 50,
        marginTop: 25,
        backgroundColor: 'rgb(35,136,211)',
        marginBottom: 25,
        fontFamily: "Raleway-Bold",
    }
}))


const theme = createMuiTheme({
    palette: {
        primary: {
            main: 'rgb(35,136,211)' //your color
        }
    }
});

export function InvitationDialog({
    open,
    onBackdropClick,
    onSubmit,
    token,
}) {
    const classes = useStyles();

    const [email, setEmail] = useState('');
    const [name, setName] = useState();
    const [isMultipleInvite, setIsMultipleInvite] = useState(false);

    // to validate email
    const [emailError, setEmailError] = useState('');

    // submit status
    const [loading, setLoading] = useState(false);

    function emailValidated(email) {
        let re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(email);
    }

    async function handleSubmit() {
        let emails = [];
        if (isMultipleInvite) {
            let list = email.split(',');
            emails.push(...list.map((val) => val.trim()));
        } else {
            emails.push(email.trim());
        }


        for (let val of emails) {
            if (!emailValidated(val)) {
                setEmailError('Invalid Email');
                return false;
            }
        }

        setLoading(true);

        let success = false;
        let message;

        let url = 'https://us-central1-pauseable-e4aa1.cloudfunctions.net/teamInvite';
        let config = {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        };

        let data = {
            emails: emails,
            team: 'pauseable',
            name: name
        };

        Axios.post(url, data, config)
            .then(() => {
                success = true;
                message = 'Invitation email sent';
            })
            .catch(reason => {
                if (reason.response) {
                    message = reason.response.data.error['errors'][0]['message'];
                }
            })
            .finally(() => {
                clearState();
                // pass to parent
                onSubmit([success, message]);
            });


    }

    let singleInviteContents = (
        <div>
            <IconButton
                style={{ position: 'relative', left: 320, }}
                onClick={(_) => { clearState(); onBackdropClick(); }}
            >
                <img src={require('../img/close.png')} width="28pt" height="auto" alt="icon" draggable="false" />
            </IconButton>
            <h2 style={{ fontFamily: "Raleway-Bold", fontSize: 24, marginTop: 0 }}>Invite members</h2>
            <p style={{ fontFamily: "Raleway-Regular", fontSize: 13, lineHeight: 2 }}>Sign in to start using interactive meditation, mindful movement and intelligent guidance.</p>

            <form>
                <p className={classes.label}>EMAIL</p>
                <ThemeProvider theme={theme}>

                    <TextField
                        placeholder='Email'
                        required={true}
                        helperText={emailError}
                        error={emailError !== ''}
                        onChange={(event) => setEmail(event.target.value)}
                        fullWidth
                    />
                    <p className={classes.label}>NAME (OPTIONAL)</p>
                    <TextField
                        placeholder='Name'
                        onChange={(event) => setName(event.target.value)}
                        fullWidth
                    />
                    <Button
                        color='primary'
                        variant='contained'
                        onClick={(_) => handleSubmit()}
                        fullWidth
                        className={classes.button}
                    >
                        {loading === false
                            ? 'Send invite'
                            : <CircularProgress
                                color='primary'
                                style={{ color: 'white' }}
                            />
                        }
                    </Button>
                </ThemeProvider>
            </form>

            <p><span
                style={{
                    fontWeight: 'bold',
                    fontSize: 13,
                    color: 'rgb(54,54,54)',
                    textDecoration: 'underline',
                    cursor: 'pointer',
                }}
                onClick={(_) => singleOrMultipleInvite()}
            >
                Add many at once?
                </span></p>

        </div>
    );

    let multipleInviteContents = (
        <div>
            {/* <CancelIcon style={{ color: 'rgba(200,200,200,0.8', position: 'relative', left: 360, fontSize: 28, cursor: 'pointer', }} onClick={(_) => { setStatus(0); onBackdropClick(); }} /> */}
            <IconButton
                style={{ position: 'relative', left: 320, }}
                onClick={(_) => { clearState(); onBackdropClick(); }}
            >
                <img src={require('../img/close.png')} width="28pt" height="auto" alt="icon" draggable="false" />
            </IconButton>
            <h2 style={{ fontFamily: "Raleway-Bold", fontSize: 24, marginTop: 0, marginBottom: 0 }}>
                Invite many at once
            </h2>
            <p style={{ fontSize: 13, lineHeight: 2 }}>Enter multiple emails separated by commas</p>

            <form>
                <p className={classes.label}>Emails</p>
                <ThemeProvider theme={theme}>

                    <TextField
                        variant='outlined'
                        required={true}
                        helperText={emailError}
                        error={emailError !== ''}
                        onChange={(event) => setEmail(event.target.value)}
                        fullWidth
                        multiline={true}
                        rows={6}
                    />

                    <Button
                        color='primary'
                        variant='contained'
                        onClick={(_) => handleSubmit()}
                        fullWidth
                        className={classes.button}
                    >
                        {loading === false
                            ? 'Send Invite'
                            : <CircularProgress
                                color='primary'
                                style={{ color: 'white' }}
                            />
                        }
                    </Button>
                </ThemeProvider>

            </form>

            <p><span
                style={{
                    fontWeight: 'bold',
                    fontSize: 13,
                    color: 'rgb(54,54,54)',
                    textDecoration: 'underline',
                    cursor: 'pointer',
                }}
                onClick={(_) => singleOrMultipleInvite()}
            >
                Add single user?
                </span></p>

        </div>
    );

    function clearState() {
        setLoading(false);
        setEmail('');
        setEmailError('');
        setIsMultipleInvite(false);
    }

    function singleOrMultipleInvite() {
        setIsMultipleInvite(!isMultipleInvite);
        setEmailError('');
    }

    return (
        <Dialog
            open={open}
            // maxWidth='lg'
            onBackdropClick={(_) => {
                clearState();
                onBackdropClick();
            }}
            classes={{ paper: classes.dialog }}
        >
            {!isMultipleInvite ? singleInviteContents : multipleInviteContents}

        </Dialog>
    );
}
import React from 'react';
import { useState } from 'react';

const DoubleSegmentButton = ({
    label1,
    label2,
    onClick1 = () => { },
    onClick2 = () => { },
}) => {

    let [selectedIndex, setSelectedIndex] = useState(0);

    const segmentStyle = {
        height: '100%',
        border: 'none',
        width: '50%',
        cursor: 'pointer',
        padding: 0,
        outline: 'none',
        fontSize: 15,
        textAlign: 'center',
        transitionDuration: '0.5s',
    };

    return (
        <div
            style={{
                padding: '15px 0px'
            }}
        >
            <div
                style={{
                    height: 50,
                    minWidth: 200,
                    border: '2px solid black',
                    width: '100%',
                    borderRadius: 8,
                    overflow: 'hidden',
                    boxSizing: 'border-box',
                }}
            >
                <button
                    style={{
                        ...segmentStyle,
                        borderRadius: '8px 0px 0px 8px',
                        fontFamily: 'Raleway-Bold',
                        backgroundColor: selectedIndex === 0 ? 'rgb(255, 235, 224)' : 'white',
                    }}
                    onClick={() => {
                        setSelectedIndex(0);
                        onClick1();
                    }}
                >
                    {label1}
                </button>
                <button
                    style={{
                        ...segmentStyle,
                        borderRadius: '0px 8px 8px 0px',
                        fontFamily: 'Raleway-Regular',
                        backgroundColor: selectedIndex === 1 ? 'rgb(255, 235, 224)' : 'white',
                    }}
                    onClick={() => {
                        setSelectedIndex(1);
                        onClick2();
                    }}
                >
                    {label2}
                </button>
            </div>
        </div>
    );
}

export default DoubleSegmentButton;
import React from 'react'

const items = [
    {
        name:'Pause 2020',
        link:null,
    },
    {
        name:'Terms of usage',
        link:null,
    },
    {
        name:'Privacy Policy',
        link:null,
    },
    {
        name:'Cookies',
        link:null,
    },
]

// eslint-disable-next-line
const SignInFooter = ({})=> {

    return(
        <div
        style={{
            display:'flex',
            paddingBottom:32
        }}
        >
            {
                items.map((item,index)=> <div
                    key={index}
                    style={{
                        display:'flex',
                        alignItems:'center'
                    }}
                    >
                    <a
                    href={item.link??'#'}

                style={{
                paddingRight:20,
                    fontFamily:'Raleway-Regular',
                    fontSize:'13px',
                    lineHeight:'23px',
                    color:'rgb(54, 54, 54)',
                    textDecoration:'none'
                }
                }
                >
                    {
                        item.name
                    }
                    </a>
                        <div
                        style={{paddingRight:20}}
                        >
                        {
                            (index<items.length-1) &&
                            <div style={{
                                width:3,
                                borderRadius:3,
                                height:3,
                                backgroundColor:'rgb(54, 54, 54)'
                            }}/>
                        }
                        </div>
                    </div>
                        )
            }

        </div>
    )
}

export default SignInFooter;
import React from 'react'
import { Switch, Route, Redirect } from "react-router-dom";
import SignInPage from "./sign_in";
import CreateTeamPage from '../create_team';
import Alert from "@material-ui/lab/Alert/Alert";
import { Snackbar } from "@material-ui/core";

import { auth, db } from '../firebase/index';

class LandingPage extends React.Component {

    state = {}


    handleLogin(data) {
        this.setState({
            errorMessage: null,
            successMessage: null,
            loading: true,

        });
        // this.state.errorMessage=null;
        // this.state.successMessage=null;
        try {
            auth.signInWithEmailAndPassword(data?.email, data?.password)
                .then((u) => {
                    // console.log(u);
                })
                .catch((error) => {
                    this.setState({ errorMessage: error.message });
                })
                .finally(() => {
                    this.setState({ loading: false })
                    this.props.authListener();
                })
        }
        catch (e) {
            this.setState({ errorMessage: e.message, loading: false });
        }

    }

    handleSignUp(data) {
        this.setState({
            errorMessage: null,
            successMessage: null,
            loading: true,
        });
        // this.state.errorMessage=null;
        // this.state.successMessage=null;
        try {
            auth.createUserWithEmailAndPassword(data?.email, data?.password)
                .then((response) => {

                    //TODO: action after successful sign up -> Add user info to database

                    // this.setState({ successMessage: 'Register successful' });
                    // this.props.history.push('/login')

                    db.collection('users').doc(response.user.uid).set({
                        name: data?.name,
                        email: data?.email,
                        email_verified: false,
                        providerId: response.user.providerData[0].providerId,
                        subscriptionPlan: "free",
                    })
                })
                .catch((error) => {
                    // Handle Errors here.

                    this.setState({ errorMessage: error.message });
                    // ...
                }).finally(() => {
                    this.setState({ loading: false });
                    this.props.authListener();
                });
        }
        catch (e) {
            this.setState({ errorMessage: e.message, loading: false });
        }
    }

    handleForgotPassword(data) {

        this.setState({
            errorMessage: null,
            successMessage: null,
            loading: true
        });

        try {
            fetch(`https://us-central1-pauseable-e4aa1.cloudfunctions.net/passwordReset`,
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        email: data?.email
                    })
                })
                .then(res => res.json())
                .then((response) => {

                    if (response.error) {

                        this.setState({
                            errorMessage: response?.error?.message ?? 'Failed to send reset code',
                        })

                        return
                    }

                    this.setState({
                        email: data?.email,
                        successMessage: 'Password Reset Code sent'
                    });

                    this.props.history.push('/verify-code')
                })
                .finally(() => {
                    this.setState({ loading: false })
                })
                ;
        }

        catch (e) {
            this.setState({ errorMessage: e.message, loading: false });
        }

    }
    handleVerifyCode(data) {

        this.setState({
            errorMessage: null,
            successMessage: null,
            loading: true
        });

        try {
            fetch(`https://us-central1-pauseable-e4aa1.cloudfunctions.net/verifyResetCode`,
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(data)
                })
                .then(res => res.json())
                .then((response) => {

                    if (response.success) {
                        this.setState({
                            token: response.success?.data?.[0]?.token
                        })
                        this.props.history.push('/change-password')

                    } else {
                        this.setState({
                            errorMessage: response?.error?.message ?? 'Failed to verify reset code',
                        })
                    }

                })
                .finally(() => {
                    this.setState({ loading: false })
                })
                ;
        }

        catch (e) {
            this.setState({ errorMessage: e.message, loading: false });
        }
    }

    handlePasswordChange(data) {

        this.setState({
            errorMessage: null,
            successMessage: null,
            loading: true
        });

        try {
            fetch(`https://us-central1-pauseable-e4aa1.cloudfunctions.net/changePassword`,
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        ...(data ?? {}),
                        token: this.state.token
                    })
                })
                .then(res => res.json())
                .then((response) => {

                    if (response.success) {
                        this.setState({

                            successMessage: "Password Changed Successfully",
                        })
                    } else {
                        this.setState({
                            errorMessage: response?.error?.message ?? 'Failed to change password',
                        })
                    }

                })
                .finally(() => {
                    this.setState({ loading: false })
                })
                ;
        }

        catch (e) {
            this.setState({ errorMessage: e.message, loading: false });
        }
    }


    render() {
        return (
            <>
                <Snackbar
                    open={this.state.errorMessage || this.state.successMessage}
                    autoHideDuration={3000}
                    onClose={(_) => {
                        this.setState({ errorMessage: null, successMessage: null });
                    }}
                    anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                >
                    <Alert
                        severity={this.state.errorMessage ? 'error' : 'success'}
                        variant='filled'
                    >
                        {this.state.errorMessage || this.state.successMessage}
                    </Alert>
                </Snackbar>
                <Switch>
                    <Route path={'/login'} render={(props) => <SignInPage
                        {...props}
                        type={'login'}
                        onSubmit={(data) => {
                            this.handleLogin(data);
                        }}
                        submitting={this.state.loading}
                        validation_errors={null}

                    /*validation_errors format:
                    {
                        field_name: 'error',
                        ...
                    }*/
                    />} />
                    <Route path={'/forgot-password'} render={(props) => <SignInPage
                        {...props}
                        type={'forgot-password'}
                        onSubmit={(data) => {
                            this.handleForgotPassword(data)

                        }}
                        submitting={this.state.loading}
                        validation_errors={null}

                    /*validation_errors format:
                    {
                        field_name: 'error',
                        ...
                    }*/
                    />} />
                    <Route path={'/verify-code'} render={(props) => <SignInPage
                        {...props}
                        type={'verify-code'}
                        onSubmit={(data) => {
                            this.handleVerifyCode(data)
                        }}
                        submitting={this.state.loading}
                        validation_errors={null}
                        initial_values={{ email: this.state.email }}

                    /*validation_errors format:
                    {
                        field_name: 'error',
                        ...
                    }*/
                    />} />

                    <Route path={'/change-password'} render={(props) => <SignInPage
                        {...props}
                        type={'change-password'}
                        onSubmit={(data) => {
                            this.handlePasswordChange(data)
                        }}
                        submitting={this.state.loading}
                        validation_errors={null}
                        initial_values={{ email: this.state.email }}

                    /*validation_errors format:
                    {
                        field_name: 'error',
                        ...
                    }*/
                    />} />


                    <Route path={'/sign-up'} render={(props) => <SignInPage
                        {...props}
                        type={'sign-up'}
                        onSubmit={(data) => {
                            this.handleSignUp(data);
                        }}
                        submitting={this.state.loading}
                        validation_errors={null}
                    />} />

                    <Route
                        path={'/create-team'}
                        render={(props) => <CreateTeamPage {...props} authListener={this.props.authListener}/>}
                    />
                    
                    <Redirect to={'/login'} />
                </Switch>
            </>
        )
    }
}

export default LandingPage;
import React from 'react';
import { Switch, Route, Redirect, Link} from 'react-router-dom';
import {AppBar, Toolbar, IconButton, Menu, MenuItem, SvgIcon, Tooltip} from '@material-ui/core';

import {auth, db} from './firebase/index';

import Dashboard from './dashboard';
import {TeamManagementPage} from './team_management';
import {useState} from 'react';
import {useEffect} from 'react';


function Home({
                  history, // for router
                  user, // user object
                  authListener,
              }) {

    function handleLogout() {
        auth.signOut().then(() => authListener());
    }

    // data state
    const [token, setToken] = useState('');
    const [role, setRole] = useState('');
    const [teams, setTeams] = useState([]);
    const [currentTeam, setCurrentTeam] = useState('');

    // ui state
    const [menuAnchor, setMenuAnchor] = useState(null);

    // load initial data
    useEffect(() => {
        user.getIdToken()
            .then(async (token) => {
                let userData = await db.collection('users').doc(user.uid).get();
                let teamMap = userData.data().teams;
                let teamList = Object.keys(teamMap);
                // set state
                setToken(token);
                setCurrentTeam(teamList[0]);
                setTeams(teamList);
            })
            .catch((err) => {
                console.log(err)
            });
    }, [user])

    // change role on changing team
    useEffect(() => {
        if (currentTeam)
            db.collection('teams').doc(currentTeam)
                .collection('members').doc(user.uid).get()
                .then(docSnap => {
                    if (docSnap.exists) {
                        setRole(docSnap.data().role);
                    } else (console.log('no role'))
                })
    }, [currentTeam, user])


    let appBar = <AppBar
        position="static"
        color='inherit'
        style={{
            borderBottom: 'solid 3px #f7f7f7',
            boxShadow: "none"
        }}>
        <Toolbar className='toolbar'>
            {/* Home */}
            <Link to={'/dashboard'}>
                <Tooltip title='Home' arrow>
                    <IconButton>
                        <img
                            src={require('./img/scrubber.svg')}
                            width='25'
                            height='auto'
                            alt='icon'
                        />
                    </IconButton>
                </Tooltip>
            </Link>

            {/* select team */}
            <Tooltip title='Select team' arrow>
                <IconButton
                    onClick={(event) => setMenuAnchor(event.currentTarget)}
                >
                    <img
                        src={require('./img/team.png')}
                        width='25'
                        height='auto'
                        alt='icon'
                    />
                </IconButton>
            </Tooltip>

            {/* team list */}
            <Menu
                anchorEl={menuAnchor}
                getContentAnchorEl={null}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                keepMounted
                open={Boolean(menuAnchor)}
                onClose={() => setMenuAnchor(null)}
            >
                {teams.map(item =>
                    <MenuItem
                        key={item}
                        selected={currentTeam === item}
                        onClick={(_) => {
                            setCurrentTeam(item);
                            setMenuAnchor(null);
                        }}
                    >
                        {item}
                    </MenuItem>
                )
                }
            </Menu>

            {/* setting button */}
            <Link to='/team'>
                <Tooltip title='Team setting' arrow>
                    <IconButton>
                        <SvgIcon id="Icon_feather-settings" data-name="Icon feather-settings"
                                 xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 36 36"
                                 stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="3">
                            <path id="Path_6" data-name="Path 6"
                                  d="M22.5,18A4.5,4.5,0,1,1,18,13.5,4.5,4.5,0,0,1,22.5,18Z" fill="none"/>
                            <path id="Path_7" data-name="Path 7"
                                  d="M29.1,22.5a2.475,2.475,0,0,0,.495,2.73l.09.09a3,3,0,1,1-4.245,4.245l-.09-.09a2.5,2.5,0,0,0-4.23,1.77V31.5a3,3,0,1,1-6,0v-.135A2.475,2.475,0,0,0,13.5,29.1a2.475,2.475,0,0,0-2.73.495l-.09.09A3,3,0,1,1,6.435,25.44l.09-.09a2.5,2.5,0,0,0-1.77-4.23H4.5a3,3,0,1,1,0-6h.135A2.475,2.475,0,0,0,6.9,13.5a2.475,2.475,0,0,0-.5-2.73l-.09-.09A3,3,0,1,1,10.56,6.435l.09.09a2.475,2.475,0,0,0,2.73.495h.12A2.475,2.475,0,0,0,15,4.755V4.5a3,3,0,0,1,6,0v.135a2.5,2.5,0,0,0,4.23,1.77l.09-.09a3,3,0,1,1,4.245,4.245l-.09.09a2.475,2.475,0,0,0-.5,2.73v.12A2.475,2.475,0,0,0,31.245,15H31.5a3,3,0,0,1,0,6h-.135A2.475,2.475,0,0,0,29.1,22.5Z"
                                  fill="none"/>
                        </SvgIcon>
                    </IconButton>
                </Tooltip>
            </Link>

            {/* logout button */}
            <IconButton
                style={{marginLeft: "71vw"}}
                onClick={handleLogout}
            >
                <img alt=''
                     src={require('./img/img1.png')}
                     width="26pt"
                />
            </IconButton>
        </Toolbar>
    </AppBar>;


    return (
        <>
            {appBar}

            <main>
                <Switch>
                    <Route path='/dashboard'
                           render={() =>
                               <Dashboard
                                   user={user}
                                   token={token}
                                   currentTeam={currentTeam}
                               />
                           }
                    />

                    <Route path='/team'
                           render={() =>
                               <TeamManagementPage
                                   token={token}
                                   role={role}
                                   teamName={currentTeam}
                               />
                           }
                    />

                    <Redirect to='/dashboard'/>
                </Switch>
            </main>
        </>
    )

}

export default Home;
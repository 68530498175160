import React from 'react';

const Header = ({
    onCloseButtonClick // for routes
}) => {
    return (
        <div
            style={{
                display: 'flex',
                alignItems: 'center',
            }}
        >
            <div
                style={{
                    fontFamily: 'Raleway-Medium',
                    fontSize: 14,
                    lineHeight: '22px',
                    color: 'rgba(0,0,0,0.5)',
                    cursor: 'pointer',
                    marginLeft: 'auto',
                    marginRight: 0,
                }}

                onClick={onCloseButtonClick}
            >
                Close
            </div>
        </div>
    );
}

export default Header;